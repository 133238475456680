.--blog-card {
    position: relative;
    width: 100%;
    display: grid;
    cursor: pointer;
    min-width: 100%;
    overflow: hidden;
    place-items: center;
    transition: .4s ease all;
}

.--blog-card .--a-blog-card {
    display: block;
    width: 100%;
}

.--blog-card .--blog-card-img {
    width: 100%;
    height: 150px;
    min-width: 100%;
    border-radius: 10px;
    border: 1px solid #d6ebfd30;
}

.--blog-card .--blog-card-txt {
    padding: 1rem .5rem;
}

.--blog-card .--blog-card-txt .--row {
    width: 100%;
    display: flex;
    gap: 1rem;
    
    margin: 1rem 0rem;
    align-items: center;
}

.--blog-card .--blog-card-txt .--row .--avatar {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #d6ebfd30;
}

.--blog-card .--blog-card-txt .--row .--data-p {
    font-size: .9rem;
}

.--blog-card .--blog-card-txt .--row .--data-p .--data-p-name {
    font-weight: 500;
}

.--blog-card .--blog-card-txt .--row .--data-p .--data-p-date {
    font-size: .8rem;
    color: #888888;
}