.--partners {
    width: 100%;
    padding: 4rem 0rem;
}

.--partners .--title {
    text-align: center;
    margin-bottom: 2rem;
}

.--partners .--title h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--partners .--title p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}

.--partners .--ctgs {
    width: 100%;
    margin: 2rem 0rem;
}

.--partners .--ctgs .--ctgs-list {
    width: 80%;
    margin: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--partners .--ctgs .--ctgs-list .--ctgs-item {
    cursor: pointer;
    font-size: .9rem;
    font-weight: 500;
    padding: .8rem 1rem;
    border-radius: 10px;
    border: 1px solid #d6ebfd30;
}
.--partners .--ctgs .--ctgs-list .--ctgs-item--active {
    border: none;
    color: var(--black-950);
    background-color: var(--black-50);
}


.--partners .--grd-partners {
    width: 80%;
    margin: auto;
    display: grid;gap: 4rem;
    grid-template-columns: repeat(1, 1fr);
}
.--partners .--grd-partners  .--card-partner {
    position: relative;
    width: 100%;
    display: grid;
    padding: 1rem;
    height: 400px;
    cursor: pointer;
    overflow: hidden;
    place-items: center;
    border-radius: 10px;
    transition: .4s ease all;
    border: 1px solid #d6ebfd30;
}

.--partners .--grd-partners  .--card-partner .--image-bg {
    position: absolute;
    z-index: -2;
    width: 220px;
    left: -50px;
    opacity: .6;
    bottom: -100px;
    transform: rotate(17deg);
}

.--partners .--grd-partners  .--card-partner .--image-fr {
    width: 50%;
    margin: auto;
    opacity: .8;
    transition: .4s ease all;
}
.--partners .--grd-partners  .--card-partner:hover .--image-fr {
    opacity: 1;
}

.--partners .--grd-partners  .--card-partner:hover .--image-fr {
    width: 60%;
    transition: .4s ease all;
}

.--partners .--grd-partners  .--card-partner a {
    position: absolute;
    display: grid;
    padding: .5rem 0rem;
    place-items: center;
    bottom: -40px;
    transition: .4s ease all;
}
.--partners .--grd-partners  .--card-partner:hover a {
    bottom: 20px;
    transition: .4s ease all;
}

.--partners .--grd-partners  .--card-partner .--line {
    display: none;
    bottom: 0;
    height: 0;
    z-index: -2;
    transition: .4s ease all;
}
.--partners .--grd-partners  .--card-partner:hover .--line {
    display: block;
    transition: .4s ease all;
}

@media (min-width: 1024px) {

    .--partners .--title h1 {
        font-size: 4rem;
    }

    .--partners .--title p {
        width: 60%;
    }

    .--partners .--grd-partners {
        grid-template-columns: repeat(3, 1fr);
    }

}