.--questions {
    width: 100%;
    padding: 4rem 0rem;
}

.--questions .--title {
    text-align: center;
    margin-bottom: 2rem;
}

.--questions .--title h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--questions .--title p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}


.--questions .--lst-questions {
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: auto;
    flex-direction: column;
}

.--questions .--lst-questions .--item-question {
    width: 100%;
    padding: 1rem;
    border-radius: 15px;
    transition: .4s ease all;
    border: 1px solid #d6ebfd30;
}

.--questions .--lst-questions .--item-question .--row-question {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.--questions .--lst-questions .--item-question .--row-question .--ico-question {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    cursor: pointer;
    place-items: center;
}

.--questions .--lst-questions .--item-question .--row-question h4 {
    font-weight: 500;
}

.--questions .--lst-questions .--item-question .--row-response {
    display: none;
    transition: .4s ease all;
}

.--questions .--lst-questions .--item-question--open .--row-response {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    transition: .4s ease all;
}

.--questions .--lst-questions .--item-question--open .--row-response .--ico-question {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    cursor: pointer;
    place-items: center;
}

.--questions .--lst-questions .--item-question--open .--row-response p {
    font-size: .9rem;
}

@media (min-width: 1024px) {

    .--questions .--title h1 {
        font-size: 4rem;
    }

    .--questions .--title p {
        width: 60%;
    }

    .--questions .--lst-questions {
        width: 80%;
    }

} 