.--steps {
    width: 100%;
    display: flex;
    gap: 4rem;
    padding: 4rem 0rem;
    flex-direction: column;
}

.--setp {
    width: 100%;
}


.--step .--ico {
    width: 170px;
    height: 170px;
    margin: auto;
}

.--step .--title {
    margin: 2rem 0rem;
    text-align: center;
}
.--step .--title .--h2-title {
    font-weight: 500;
    font-size: 2rem;
}

.--step .--title .--h2-title .--span-title {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.--step .--title .--h2-title .--span-title-degrade-zer {
    background-image: var(--degrade-zer);
}

.--step .--title .--h2-title .--span-title-degrade-one {
    background-image: var(--degrade-one);
}
.--step .--title .--h2-title .--span-title-degrade-two {
    background-image: var(--degrade-two);
}
.--step .--title .--h2-title .--span-title-degrade-three {
    background-image: var(--degrade-three);
}

.--step .--title .--p-paragraph {
    width: 80%;
    margin: auto;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 1em 0em;
    color: var(--black-400);
}

.--step .--row-info {
    display: flex;
    gap: 2rem;
    flex-direction: column;
}


@media (min-width: 1024px) {

    .--step .--title .--h2-title {
        font-size: 3rem;
    }

    .--step .--title .--p-paragraph {
        width: 60%;
        font-size: 1.1rem;
    }

    .--step .--row-info {
        flex-direction: row;
    }

    .--step .--row-info .--col-info .--bodt p {
        font-size: 1rem;
    }

}