.--section-me {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 4rem 0rem;
}

.--line {
    top: 0;
    left: 0;
    width: 100%;
    margin: auto;
    height: 1px;
    position: absolute;
    background-color: var(--black-900);
    box-shadow: 0px 0px 60px 90px rgba(44,44,44,.4);
}


.--logo-me {
    width: 120px;
    height: 120px;
    margin: auto;
    display: grid;
    place-items: center;
    border-radius: 100px;
    background-color: var(--black-950);
    box-shadow: 0px 14px 60px 20px rgba(44,44,44,.2);
}

.--lopp {
    width: 50%;
}

.--phrase {
    width: 100%;
    margin: 2rem auto;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    line-height: 2rem;
}

.--ceo-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.--ceo-content .--avatar {
    width: 60px;
    height: 60px;
    min-width: 60px;
    border-radius: 100px;
    border: 1px solid var(--black-400);
}

.--ceo-content .--ceo h4 {
    font-size: 1.2rem;
}

.--ceo-content .--ceo p {
    font-size: .8rem;
    color: var(--black-400);
}

@media (min-width: 768px) {

    .--line {
        left: 20%;
        width: 60%;
    }

    .--phrase {
        width: 40%;
        font-size: 1.2rem;
    }

}