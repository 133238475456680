.--card-proyects {
    position: relative;
    width: 100%;
    display: grid;
    padding: 1rem;
    height: 400px;
    cursor: pointer;
    overflow: hidden;
    place-items: center;
    border-radius: 10px;
    transition: .4s ease all;
    border: 1px solid #d6ebfd30;
}

.--card-proyects .--line {
    display: none;
    bottom: 0;
    height: 0;
    z-index: -2;
    transition: .4s ease all;
}
.--card-proyects:hover .--line {
    display: block;
    transition: .4s ease all;
}

.--card-proyects a {
    position: absolute;
    display: grid;
    padding: .5rem 0rem;
    place-items: center;
    bottom: -40px;
    transition: .4s ease all;
}
.--card-proyects:hover a {
    bottom: 20px;
    transition: .4s ease all;
}