.--proyects {
    width: 100%;
    padding: 4rem 0rem;
}

.--proyects .--title {
    text-align: center;
    margin-bottom: 2rem;
}

.--proyects .--title h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--proyects .--title p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}

.--proyects .--ctgs {
    width: 100%;
    margin: 2rem 0rem;
}

.--proyects .--ctgs .--ctgs-content-lst {
    position: relative;
    width: 100%;
    margin: auto;
}

.--proyects .--ctgs .--ctgs-content-lst .--btn-scroll {
    display: none;
}

.--proyects .--ctgs .--ctgs-list {
    width: 100%;
    margin: auto;
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    align-items: center;
}

.--proyects .--ctgs .--ctgs-list::-webkit-scrollbar {
    display: none;
}

.--proyects .--ctgs .--ctgs-list .--ctgs-item {
    cursor: pointer;
    font-size: .9rem;
    font-weight: 500;
    text-wrap: nowrap;
    padding: .8rem 1rem;
    border-radius: 10px;
    border: 1px solid #d6ebfd30;
}
.--proyects .--ctgs .--ctgs-list .--ctgs-item--active {
    border: none;
    color: var(--black-950);
    background-color: var(--black-50);
}



.--grd-proyects {
    width: 100%;
    margin: auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}


@media (min-width: 1024px) {

    .--proyects .--title h1 {
        font-size: 4rem;
    }

    .--proyects .--title p {
        width: 60%;
    }


    .--proyects .--ctgs .--ctgs-content-lst {
        width: 80%;
    }

    .--proyects .--ctgs .--ctgs-content-lst .--btn-scroll {
        position: absolute;
        top: 0;
        cursor: pointer;
        display: grid;
        width: 40px;
        height: 40px;
        background: none;
        place-items: center;
        border-radius: 100px;
        backdrop-filter: blur(25px);
        background-color: rgba(255, 255, 255, .3);
    }
    
    .--proyects .--ctgs .--ctgs-content-lst .--btn-scroll .tabler-icon {
        stroke: var(--black-50);
    }
    
    .--proyects .--ctgs .--ctgs-content-lst .--btn-scroll-left {
        left: -50px;
    }
    
    .--proyects .--ctgs .--ctgs-content-lst .--btn-scroll-right {
        right: -50px;
    }

    .--grd-proyects {
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
    }

}