.--section-comment {
    width: 100%;
    padding: 4rem 0rem;
}

.--titles {
    text-align: center;
    margin-bottom: 2rem;
}

.--titles h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--titles p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}

.--slide-comments {
    width: 100%; /* Ajustar según sea necesario */
    padding: 2rem 0rem;
    overflow: hidden;
}

.--comments {
    display: grid;
    gap: 2rem;
    grid-auto-flow: column;
    grid-template-columns: repeat(6, 20rem); /* Ajustar según sea necesario */
    justify-items: stretch;
    animation: scrollSlide 60s linear infinite;
}

.--comment {
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid var(--black-400);
    transition: transform 0.3s ease; /* Añadido para la transición de escala */
}

.--comment:hover {
    transform: scale(1.1); /* Escala al hacer hover */
    animation-play-state: paused; /* Pausa la animación en hover */
}

.--comment .--comment-txt {
    font-size: .8rem;
    text-align: left;
    line-height: 1.3rem;
    margin-bottom: 1rem;
}

.--comment .--c-daa {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.--comment .--c-daa .--avat {
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 100px;
    border: 1px solid var(--black-400);
}
.--comment .--c-daa .--dtad span {
    font-size: .8rem;
    font-weight: 500;
    color: var(--black-400);
}

@keyframes scrollSlide {
    to {
        transform: translateX(calc(-6 * 20rem)); /* Ajustar según sea necesario */
    }
}



@media (min-width: 1024px) {

    .--titles h1 {
        font-size: 4rem;
    }
    
    .--titles p {
        width: 60%;
    }

}