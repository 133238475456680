.--about {
    width: 100%;
    padding: 4rem 0rem;
    background-size: 40px 40px;
    background-image: radial-gradient(circle at 1px 1px, rgb(103, 103, 103) 1px, transparent 0px);
    background-position: center center;
    transform: translateZ(-500px);
}

.--about .--figure-logo .--lgo {
    width: 270px;
    height: 270px;
    margin: auto;
}

.--about .--figure-logo .--tti-lg h1 {
    font-size: 2.5rem;
    text-align: center;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}
.--about .--figure-logo .--tti-lg h1 span {
    display: block;
}
.--about .--figure-logo .--tti-lg p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
    text-align: center;
}


.--ourabout {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 4rem 0rem;
}

.--ourabout .--line {
    top: 0;
    left: 350px;
    width: 40%;
    margin: auto;
    height: 1px;
    position: absolute;
    background-color: var(--black-900);
    box-shadow: 0px 0px 60px 90px rgba(44,44,44,.4);
}

.--ourabout .--tti {
    text-align: center;
    margin-bottom: 2rem;
}

.--ourabout .--tti h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}


.--ourabout .--prr {
    width: auto;
    margin: auto;
}

.--ourabout .--prr p {
    line-height: 2rem;
    margin-bottom: 2rem;
}

@media (min-width: 1024px) {

    .--about .--figure-logo .--tti-lg h1 {
        font-size: 4rem;
    }

    .--about .--figure-logo .--tti-lg p {
        width: 60%;
    }

    .--ourabout .--prr {
        width: 40%;
    }

}