.--pricing {
    width: 100%;
    padding: 4rem 0rem;
}

.--pricing .--title {
    text-align: center;
    margin-bottom: 2rem;
}

.--pricing .--title h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--pricing .--title p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}

.--pricing .--grd-prices {
    width: 100%;
    display: grid;gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}

.--pricing .--grd-prices .--card-price {
    position: relative;
    width: 100%;
    padding: 1rem;
    cursor: pointer;
    overflow: hidden;
    border-radius: 20px;
    transition: .4 ease all;
    border: 1px solid #d6ebfd30;
}

.--pricing .--grd-prices .--card-price .--line {
    display: none;
    height: 0;
    transition: .4 ease all;
}

.--pricing .--grd-prices .--card-price:hover .--line {
    display: block;
    transition: .4 ease all;
}

.--pricing .--grd-prices .--card-price .--h3-tt-card {
    font-size: .8rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
}
.--pricing .--grd-prices .--card-price .--span-tt-card {
    text-align: center;
    margin-bottom: 1rem;
}
.--pricing .--grd-prices .--card-price .--span-tt-card .--span-block {
    display: block;
}

.--pricing .--grd-prices .--card-price .--span-tt-card .--span-price-lg {
    font-size: 2.5rem;
}

.--pricing .--grd-prices .--card-price .--line-card {
    width: 100%;
    height: 1px;
    margin: 2rem 0rem;
    background-color: #d6ebfd30;
}

.--pricing .--grd-prices .--card-price .--list-offer {
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.--pricing .--grd-prices .--card-price .--list-offer .--item-offer {
    width: 100%;
    display: flex;
    gap: .5rem;
    align-items: center;
}

.--pricing .--grd-prices .--card-price .--list-offer .--item-offer .--ico-offer {
    display: grid;
    width: 40px;
    height: 40px;
    min-width: 40px;
    place-items: center;
}

.--pricing .--grd-prices .--card-price .--list-offer .--item-offer .--ico-offer .tabler-icon-circle-check {
    fill: #2daa6e;
    stroke: var(--black-950);
}

.--pricing .--grd-prices .--card-price .--list-offer .--item-offer .--ico-offer .tabler-icon-circle-x {
    fill: #d50000;
    stroke: var(--black-950);
}

.--pricing .--grd-prices .--card-price .--list-offer .--item-offer .--txt-offer .--txt-offer-small {
    font-size: .8rem;
    color: var(--black-400);
}

.--pricing .--grd-prices .--card-price .--btn-offer {
    display: grid;
    width: 100%;
    height: 40px;
    font-size: .9rem;
    margin-top: 2rem;
    font-weight: 500;
    place-items: center;
    border-radius: 100px;
    color: var(--black-950);
    background-color: var(--black-50);
}

@media (min-width: 1024px) {

    .--pricing .--title h1 {
        font-size: 4rem;
    }

    .--pricing .--title p {
        width: 60%;
    }


    .--pricing .--grd-prices {
        grid-template-columns: repeat(4, 1fr);
    }

}