.--blog-post {
    width: 100%;
    padding: 4rem 0rem;
}

.--blog-post .--title {
    text-align: center;
    margin-bottom: 2rem;
}

.--blog-post .--title h1 {
    font-size: 2.5rem;
    font-weight: 500;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: var(--degrade-zer);
}

.--blog-post .--title p {
    width: 100%;
    margin: auto;
    font-size: 1rem;
    line-height: 2rem;
    padding: 1rem 0rem;
}

.--blog-post .--title .--author {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.--blog-post .--title .--author .--avatar-author {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 100px;
    border: 1px solid #d6ebfd30;
}

.--blog-post .--new-grd-news {
    width: 100%;
    margin: 0px auto 2rem auto;
    display: grid;gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}

.--blog-post h3 {
    width: 80%;
    margin: auto;
}

.--blog-post .--lst-grd-news {
    width: 100%;
    margin: 2rem auto;
    display: grid;gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
}


@media (min-width: 1024px) {

    .--blog-post .--title h1 {
        font-size: 4rem;
    }

    .--blog-post .--title p {
        width: 60%;
    }

    .--blog-post .--new-grd-news {
        width: 80%;
        grid-template-columns: repeat(2, 1fr);
    }

    .--blog-post .--lst-grd-news {
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
    }

}