.--col-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.--col-info .--figure {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
}

.--col-info .--figure .--line {
    height: 0;
    top: -50px;
}

.--col-info .--head {
    display: flex;
    gap: 1em;
    padding: .5rem 0rem;
    flex-direction: column;
}

.--col-info .--head .--icn {
    width: 40px;
    height: 40px;
    display: grid;
    border-radius: 10px;
    place-items: center;
    background-color: var(--black-900);
    box-shadow: 0px 1px 10px 1px rgb(164 164 164 / 30%);
}

.--col-info .--bodt p {
    font-size: .9rem;
    line-height: 1.5rem;
    color: var(--black-400);
}