.--footer {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.--content-footer {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 4rem;
    padding: 4rem 0rem;
    flex-direction: column;
}

.--content-footer .--col-F-A {
    width: 100%;
}

.--content-footer .--col-F-A .--content-red {
    width: auto;
    margin: auto;
    text-align: left;
}

.--content-footer .--col-F-A .--content-red p {
    margin-bottom: 1rem;
}

.--content-footer .--col-F-A .--content-red .--reds {
    display: flex;
    gap: 1em;
    margin: 2rem 0rem;
    align-items: center;
    justify-content: flex-start;
}

.--content-footer .--col-F-A .--content-red .--reds .--red a {
    display: grid;
    width: 40px;
    height: 40px;
    place-items: center;
    border-radius: 100px;
    border: 1px solid var(--black-900);
}

.--content-footer .--col-F-B {
    width: 100%;
    display: flex;
    gap: 4rem;
    text-align: left;
    flex-direction: column;
    justify-content: center;
}

.--content-footer .--col-F-B ul {
    width: 100%;
}

.--content-footer .--col-F-B ul h4 {
    margin-bottom: 1rem;
}

.--content-footer .--col-F-B ul li a {
    display: block;
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
    color: var(--black-400);
    transition: .4s ease all;
}
.--content-footer .--col-F-B ul li a:hover {
    color: var(--black-50);
    transition: .4s ease all;
}


@media (min-width: 768px) {
    .--content-footer {
        flex-direction: row;
    }
}

@media (min-width: 1024px) {

    .--content-footer .--col-F-A .--content-red {
        width: 60%;
    }

    .--content-footer .--col-F-B {
        flex-direction: row;
    }
}