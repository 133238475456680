:root {

    --black-50: #f6f6f6;
    --black-100: #e7e7e7;
    --black-200: #d1d1d1;
    --black-300: #b0b0b0;
    --black-400: #888888;
    --black-500: #6d6d6d;
    --black-600: #5d5d5d;
    --black-700: #4f4f4f;
    --black-800: #454545;
    --black-900: #3d3d3d;
    --black-950: #000000;

    --start-color-one: #007cf0;
    --end-color-one: #00dfd8;

    --start-color-two: #7928ca;
    --end-color-two: #ff0080;

    --start-color-three: #ff4d4d;
    --end-color-three: #f9cb28;

    --degrade-zer: linear-gradient(to right bottom,#fff 30%,hsla(0,0%,100%,.5));
    --degrade-one: linear-gradient(90deg,var(--start-color-one),var(--end-color-one));
    --degrade-two: linear-gradient(90deg,var(--start-color-two),var(--end-color-two));
    --degrade-three: linear-gradient(90deg,var(--start-color-three),var(--end-color-three));

    --font-family: "Montserrat",sans-serif;

}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/static/Montserrat-Regular.ttf') format('opentype');
    font-weight: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/static/Montserrat-SemiBold.ttf') format('opentype');
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/static/Montserrat-Bold.ttf') format('opentype');
    font-weight: bold;
}

* {
    margin: 0;
    padding: 0;
    user-select: none;
    box-sizing: border-box;
    font-family: var(--font-family);
}

body {
    color: var(--black-50);
    background-color: var(--black-950);
}

.--isnt-scrolling-down {
    overflow-y: hidden;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

#root {
    width: 100%;
    height: 100%;
}

.--content-error {
    position: relative;
    width: 100%;
    height: 100vh;
}

.--a-back {
    position: fixed;
    bottom: 60px;
    left: calc(100vw - 50%);
    font-weight: 500;
    display: inline-flex;
    padding: .8rem 1rem;
    border-radius: 100px;
    color: var(--black-950);
    background-color: var(--black-50);
}

ul, li, ol {
    list-style: none;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
}

a {
    color: var(--black-50);
    text-decoration: none;
}

.tabler-icon {
    stroke-width: 1.2;
}

