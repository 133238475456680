.--main {
    width: 100%;
    padding-top: 80px;
}

.--content {
    width: 90%;
    margin: auto;
}

@media (min-width: 1024px) {

    .--content {
        width: 80%;
    }

}