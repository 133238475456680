.--header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 10001;
}

.--header-glass {
    backdrop-filter: blur(25px);
    background-color: rgba(0, 0, 0, .2);
}

.--content-header {
    position: relative;
    width: 100%;
    height: inherit;
}

.--box {
    width: 90%;
    margin: auto;
    display: flex;
    height: inherit;
    align-items: center;
    justify-content: space-between;
}

.--col {
    display: flex;
    height: inherit;
    align-items: center;
}

.--col-A .--logo {
    width: 50%;
}
.--col-A .--logo .--a-logo {
    display: block;
}


.--col-B .--ul-nav {
    display: none;
}

.--col-C .--ul-options .--li-options .--a-options {
    display: none;
}

.--col-C .--ul-options .--li-options .--a-options-btn {
    display: grid;
    width: 40px;
    height: 40px;
    background: none;
    place-items: center;
    border-radius: 100px;
    border: 1px solid var(--black-900);
}
.--col-C .--ul-options .--li-options .--a-options-btn .tabler-icon {
    stroke: var(--black-50);
}


.--content-header .--mnu {
    position: absolute;
    top: 60px;
    right: -100%;
    width: 100%;
    transition: .4s ease all;
    height: calc(100dvh - 60px);
    background-color: rgba(0, 0, 0);
}

.--content-header .--mnu--active {
    right: 0;
    transition: .4s ease all;
}

.--content-header .--mnu .--mnu-ul {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 2rem;
    text-align: center;
    padding: 2rem 0rem;
    flex-direction: column;
}

.--content-header .--mnu .--mnu-ul .--mnu-li .--a-nav {
    display: grid;
    width: 100%;
    padding: 1rem 0rem;
    place-items: center;
    color: var(--black-400);
}

.--content-header .--mnu .--mnu-ul .--mnu-li .--a-nav--active {
    font-weight: 500;
    color: var(--black-50);
}

.--content-header .--mnu .--mnu-ul .--mnu-li .--a-nav--btn {
    display: grid;
    width: 100%;
    font-weight: 500;
    padding: 1rem 0rem;
    place-items: center;
    border-radius: 100px;
    color: var(--black-950);
    background-color: var(--black-50);
}

@media (min-width: 1024px) {

    .--col-B .--ul-nav {
        display: flex;
        gap: 1em;
        align-items: center;
    }
    
    .--col-B .--ul-nav .--li-nav .--a-nav {
        display: flex;
        font-size: .9rem;
        font-weight: 500;
        padding: .8em 1em;
        align-items: center;
        border-radius: 100px;
        color: var(--black-400);
        transition: .4s ease all;
    }
    
    .--col-B .--ul-nav .--li-nav .--a-nav:hover {
        color: var(--black-50);
    }
    .--col-B .--ul-nav .--li-nav .--a-nav--active {
        color: var(--black-50);
    }
    
    .--col-C .--ul-options {
        display: flex;
        align-items: center;
    }
    
    .--col-C .--ul-options .--li-options .--a-options {
        display: flex;
        gap: .2em;
        font-size: .9rem;
        font-weight: 500;
        padding: .8em 1em;
        align-items: center;
        border-radius: 100px;
        color: var(--black-950);
        transition: .4s ease all;
        background-color: var(--black-50);
    }
    
    .--col-C .--ul-options .--li-options .--a-options .tabler-icon {
        width: 14px;
        height: 14px;
        stroke-width: 1;
        stroke: var(--black-400);
    }
    
    .--col-C .--ul-options .--li-options .--a-options:hover {
        background-color: var(--black-200);
    }

    .--col-C .--ul-options .--li-options .--a-options-btn {
        display: none;
    }

}