.--hero {
    width: 100%;
    display: flex;
    gap: 2rem;
    padding-bottom: 2rem;
    align-items: center;
    flex-direction: column-reverse;
}

.--col-main {
    width: 100%;
}

.--col-main-A .--content-col {
    text-align: center;
}

.--col-main-A .--content-col .--a-hero-call {
    display: inline-block;
    height: 32px;
    font-size: .8rem;
    font-weight: 500;
    margin-bottom: 1rem;
    border-radius: 100px;
    transition: .4s ease all;
    background: var(--degrade-two);
}

.--col-main-A .--content-col .--a-hero-call:hover {
    transform: scale(1.02);
}

.--col-main-A .--content-col .--a-hero-call span {
    display: inline-flex;
    text-wrap: nowrap;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    align-items: center;
    border-radius: 100px;
    width: calc(100% - 2px);
    height: calc(32px - 2px);
    background-color: var(--black-950);
}

.--col-main-A .--content-col .--h1-title {
    font-size: 3rem;
    text-align: center;
}

.--col-main-A .--content-col .--h1-title .--span-title {
    display: block;
}

.--col-main-A .--content-col .--p-text {
    text-align: center;
    margin: 2rem 0;
}

.--col-main-A .--content-col .--a-hero {
    display: inline-block;
    padding: 1em 2em;
    font-weight: 500;
    border-radius: 100px;
    color: var(--black-950);
    background-color: var(--black-50);
}

.--col-main-B canvas {
    margin: auto;
    width: 100% !important;
    height: 400px !important;
}


@media (min-width: 1024px) {

    .--hero {
        height: 600px;
        flex-direction: row;
        justify-content: space-between;
    }

    .--col-main-A .--content-col {
        text-align: left;
    }

    .--col-main-A .--content-col .--h1-title {
        text-align: left;
        font-size: 4rem;
    }

    .--col-main-A .--content-col .--p-text {
        text-align: left;
    }


    .--col-main-B canvas {
        width: 400px !important;
        height: 400px !important;
    }

}